import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useThemeState } from "@panel2/systail-ui";
import { Badge } from "@panel2/systail-ui/badge";
import { Button } from "@panel2/systail-ui/button";
import { Input } from "@panel2/systail-ui/form/input";
import { Tooltip } from "@panel2/systail-ui/tooltip";
import { Multiply } from "components/atoms/icons";
import { useUserCtx } from "components/hoc/user/provider";
import defaultBg from "util/default_bg";
import { defaultSchemeDark, defaultSchemeLight } from "utils";

const CompanyChooser = (): JSX.Element => {
  const navigate = useNavigate();

  const [knownSlugs, _setKnownSlugs] = useState<string[]>(() => {
    try {
      const slugs = localStorage.getItem("known_slugs");
      if (slugs) {
        const parsed = JSON.parse(slugs);

        if (
          Array.isArray(parsed) &&
          parsed.every((s) => typeof s === "string")
        ) {
          return parsed;
        }
      }
    } catch {}

    return [];
  });

  const [slugCache, setSlugCache] = useState<string>();
  const [, setTheme] = useThemeState();

  const setKnownSlugs = useCallback((slugs: string[]) => {
    _setKnownSlugs(slugs);
    localStorage.setItem("known_slugs", JSON.stringify(slugs));
  }, []);

  const onChoose = (slug: string) => {
    navigate(`/${slug}/login`);

    if (!knownSlugs.some((s) => s === slug)) {
      setKnownSlugs([...knownSlugs, slug]);
    }
  };

  const { user } = useUserCtx();
  const logged = user !== undefined;

  useEffect(() => {
    const bg = document.getElementById("background");

    if (bg) {
      bg.innerHTML = defaultBg;
    }
  }, []);

  useEffect(() => {
    setTheme({
      mode: "light",
      light: defaultSchemeLight,
      dark: defaultSchemeDark,
    });
  }, [setTheme]);

  return logged ? (
    <Navigate to={`/${user.companySlug}`} />
  ) : (
    <form
      className="flex h-full w-full"
      onSubmit={(e) => {
        e.preventDefault();

        const slug = slugCache || knownSlugs[0];

        if (slug) {
          onChoose(slug);
          setSlugCache("");
        }
      }}
    >
      <div className="delayed-grow flex max-w-[1000px] grow">
        <div className="m-auto lg:ml-auto">
          <div className="rounded-t border-x-4 border-t-4 border-surface-variant bg-surface px-5 pb-2 pt-3 drop-shadow-xl">
            <h2 className="my-2 select-none text-2xl text-on-surface">
              <strong>
                <span>
                  {knownSlugs.length === 0
                    ? t`titles.enter_company|Enter company`
                    : t`titles.choose_company|Choose company`}
                </span>
              </strong>
            </h2>
          </div>
          <div className="m-0 h-1" />

          <div className="flex flex-col rounded-b border-x-4 border-b-4 border-surface-variant bg-surface px-4 pb-3 pt-4 drop-shadow-xl">
            <div className="flex flex-wrap">
              {knownSlugs.map((name) => (
                <Badge
                  $as="div"
                  key={name}
                  data-cmp="login:company:known"
                  className="relative text-base mb-2 mr-2 border-2 border-primary-container"
                  onClick={() => {
                    onChoose(name);
                  }}
                >
                  <div className="flex items-center justify-between">
                    <strong>{name}</strong>
                    <Tooltip label={t`labels.remove|Remove`}>
                      <Multiply
                        className="text-primary"
                        onClick={(e) => {
                          e.stopPropagation();

                          setKnownSlugs(knownSlugs.filter((s) => s !== name));
                        }}
                      />
                    </Tooltip>
                  </div>
                </Badge>
              ))}
            </div>

            <Input
              data-cmp="login:company:input"
              aria-label="company"
              type="text"
              id="company-input"
              autoFocus
              autoComplete="organization"
              aria-autocomplete="inline"
              className="border-primary-container"
              placeholder={t`placeholders.enter_company|Enter Company`}
              value={slugCache ?? ""}
              onChange={({ target }) => setSlugCache(target.value)}
            />

            <Button
              data-cmp="login:company:submit"
              className="relative mt-2"
              type="submit"
              $variant="primary"
              disabled={!slugCache && knownSlugs.length === 0}
              onClick={() => {
                const slug = slugCache;
                if (slug) {
                  onChoose(slug);
                  setSlugCache("");
                }
              }}
            >
              {t`buttons.submit|Submit`}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CompanyChooser;
