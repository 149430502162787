import {
  type Scheme,
  generateDarkScheme,
  generateLightScheme,
} from "@panel2/systail-ui/util";
export const colorPresets = {
  purple: {
    mode: "light" as const,
    primary: "#6750a4",
    secondary: "#8877bc",
    tertiary: "#ffcc00",
    lightSurfaceBase: "#fefefe",
    darkSurfaceBase: "#0d0d0d",
  },
  gray: {
    mode: "light" as const,
    primary: "#0a335b",
    secondary: "#44586c",
    tertiary: "#ffcc00",
    lightSurfaceBase: "#fefefe",
    darkSurfaceBase: "#0d0d0d",
  },
  blue: {
    mode: "dark" as const,
    primary: "#4070de",
    secondary: "#7d86a9",
    tertiary: "#ffcc00",
    lightSurfaceBase: "#fefefe",
    darkSurfaceBase: "#0d0d0d",
  },
};

export const defaultSchemeLight: Scheme = generateLightScheme({
  primary: "#ffcc00",
  secondary: "#566275",
  tertiary: "#76abca",
  surfaceBase: "#fefefe",
  radius: 0.7,
  spacing: 0.3,
});

export const defaultSchemeDark: Scheme = generateDarkScheme({
  primary: "#ffcc00",
  secondary: "#566275",
  tertiary: "#76abca",
  surfaceBase: "#0d0d0d",
  radius: 0.7,
  spacing: 0.3,
});

export const getDisabledLoginOptions = (slug: string): ("azure" | "ldap")[] => {
  const dictStr = import.meta.env.VITE_SLUG_DISABLED_LOGIN_TYPES_DICT || "{}";
  const disabledLoginOptionsDict: Record<string, ("azure" | "ldap")[]> = {};

  try {
    let dict = JSON.parse(dictStr);

    if (
      typeof dict !== "object" ||
      dict === null ||
      Object.values(dict).some((v) => typeof v !== "string")
    ) {
      dict = {};
    }

    for (const key in dict) {
      if (Object.prototype.hasOwnProperty.call(dict, key)) {
        const value = dict[key];
        disabledLoginOptionsDict[key] = value
          .split(",")
          .filter(
            (v: string): v is "azure" | "ldap" => v === "azure" || v === "ldap"
          );
      }
    }
  } catch {}

  return (
    disabledLoginOptionsDict[import.meta.env.VITE_FORCE_SLUG || slug] || []
  );
};

export const allLoginOptions: ("internal" | "azure" | "ldap")[] = [
  "internal",
  "azure",
  "ldap",
];

export const getAvailableOtherLoginOptions = (
  slug: string
): ("azure" | "ldap")[] => {
  return allLoginOptions.filter(
    (v): v is "azure" | "ldap" =>
      v !== "internal" && !getDisabledLoginOptions(slug).includes(v)
  );
};

export const popupCenter = (
  url: string,
  title: string,
  w: number,
  h: number
) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  return newWindow;
};
