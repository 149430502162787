import "base.css";
import "tabManagement";
import "translator.global";

import {
  blendHexToTheme,
  loadLastScheme,
  useCurrentScheme,
} from "@panel2/systail-ui/util";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
} from "@tanstack/react-query";
import { type ReactNode, Suspense, lazy, useInsertionEffect } from "react";

import { SystailUiProvider } from "@panel2/systail-ui/context";
import UserProvider from "components/hoc/user/UserProvider";
import { useUserCtx } from "components/hoc/user/provider";
import SessionExpiredException from "exceptions/session_expired";
import { i18nextPromise } from "i18n";
import Main from "main";
import { createRoot } from "react-dom/client";
import { defaultSchemeDark, defaultSchemeLight } from "utils";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(
      import.meta.env.MODE === "production"
        ? `${import.meta.env.VITE_ASSET_HOMEPAGE}/sw.js`
        : "/dev-sw.js?dev-sw"
    )
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });

  navigator.serviceWorker.ready;
}

const rootContainer = document.getElementById("root");
export const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools").then((m) => ({
    default: m.ReactQueryDevtools,
  }))
);
loadLastScheme();
document.body.classList.add("su-ready");

const QueryProvider = () => {
  const { logout } = useUserCtx();

  const onError = (error: Error) => {
    console.error(error);

    if (error instanceof SessionExpiredException) {
      queryClient.clear();
      logout();
    }
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 10000,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError,
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Main />
      <Suspense>
        <ReactQueryDevtools
          position="bottom"
          buttonPosition="bottom-right"
          initialIsOpen={false}
        />
      </Suspense>
    </QueryClientProvider>
  );
};

const svgColorsRgba: {
  r: number;
  g: number;
  b: number;
  a: number;
  group: "login" | "misc";
  hex?: string;
  shade?: number;
}[] = [
  // Existing login colors
  { group: "login", r: 182, g: 81, b: 96, a: 1 },
  { group: "login", r: 0, g: 63, b: 125, a: 1 },
  { group: "login", r: 192, g: 157, b: 73, a: 1 },
  { group: "login", r: 80, g: 121, b: 200, a: 1 },
  { group: "login", r: 10, g: 51, b: 91, a: 1 },
  { group: "login", r: 52, g: 52, b: 52, a: 1 },
  { r: 85, g: 177, b: 152, a: 1, group: "misc", hex: "#55b198" },
  { r: 0, g: 123, b: 255, a: 1, group: "misc", hex: "#007BFF", shade: 400 },
  { r: 0, g: 64, b: 255, a: 1, group: "misc", hex: "#0040FF", shade: 500 },
  { r: 0, g: 31, b: 127, a: 1, group: "misc", hex: "#001F7F", shade: 600 },
  { r: 0, g: 15, b: 63, a: 1, group: "misc", hex: "#000F3F", shade: 700 },
  { r: 96, g: 146, b: 192, a: 1, group: "misc", hex: "#6092c0" },
  { r: 0, g: 107, b: 107, a: 1, group: "misc", hex: "#006B6B", shade: 400 },
  { r: 0, g: 85, b: 85, a: 1, group: "misc", hex: "#005555", shade: 500 },
  { r: 0, g: 64, b: 64, a: 1, group: "misc", hex: "#004040", shade: 600 },
  { r: 0, g: 51, b: 51, a: 1, group: "misc", hex: "#003333", shade: 700 },
  { r: 211, g: 96, b: 134, a: 1, group: "misc", hex: "#d36086" },
  { r: 255, g: 152, b: 0, a: 1, group: "misc", hex: "#FF9800", shade: 400 },
  { r: 255, g: 128, b: 0, a: 1, group: "misc", hex: "#FF8000", shade: 500 },
  { r: 255, g: 102, b: 0, a: 1, group: "misc", hex: "#FF6600", shade: 600 },
  { r: 255, g: 77, b: 0, a: 1, group: "misc", hex: "#FF4D00", shade: 700 },
  { r: 145, g: 112, b: 184, a: 1, group: "misc", hex: "#9170b8" },
  { r: 255, g: 77, b: 97, a: 1, group: "misc", hex: "#FF4D61", shade: 400 },
  { r: 255, g: 51, b: 68, a: 1, group: "misc", hex: "#FF3344", shade: 500 },
  { r: 255, g: 26, b: 34, a: 1, group: "misc", hex: "#FF1A22", shade: 600 },
  { r: 255, g: 0, b: 17, a: 1, group: "misc", hex: "#FF0011", shade: 700 },
  { r: 202, g: 142, b: 174, a: 1, group: "misc", hex: "#ca8eae" },
  { r: 0, g: 128, b: 0, a: 1, group: "misc", hex: "#008000", shade: 400 },
  { r: 0, g: 102, b: 0, a: 1, group: "misc", hex: "#006600", shade: 500 },
  { r: 0, g: 77, b: 0, a: 1, group: "misc", hex: "#004D00", shade: 600 },
  { r: 0, g: 51, b: 0, a: 1, group: "misc", hex: "#003300", shade: 700 },
  { r: 214, g: 191, b: 87, a: 1, group: "misc", hex: "#d6bf57" },
  { r: 143, g: 143, b: 143, a: 1, group: "misc", hex: "#8F8F8F", shade: 400 },
  { r: 117, g: 117, b: 117, a: 1, group: "misc", hex: "#757575", shade: 500 },
  { r: 90, g: 90, b: 90, a: 1, group: "misc", hex: "#5A5A5A", shade: 600 },
  { r: 74, g: 74, b: 74, a: 1, group: "misc", hex: "#4A4A4A", shade: 700 },
];

const BackgroundSvgColorGenerator = ({ children }: { children: ReactNode }) => {
  const scheme = useCurrentScheme();

  useInsertionEffect(() => {
    const primary = scheme.primary;
    const groupCounter: Record<string, number> = {};

    for (let i = 0; i < svgColorsRgba.length; i++) {
      const color = svgColorsRgba[i];
      const group = color.group;
      const blended =
        group === "misc" ? color : blendHexToTheme(color, primary);
      const index = (groupCounter[group] ?? 0) + 1;
      groupCounter[group] = index;
      const root = document.documentElement;

      root.style.setProperty(
        `--svg-color-${group}-${index}`,
        `${blended.r}, ${blended.g}, ${blended.b}, ${blended.a}`
      );
      // console.info(
      //   ` %c --svg-color-${group}-${index}`,
      //   `background: rgba(${blended.r}, ${blended.g}, ${blended.b}, ${blended.a})`,
      // );
    }
  }, [scheme.primary]);

  return children;
};

const init = async () => {
  if (!rootContainer) throw new Error("Failed to find the root element");

  const root = createRoot(rootContainer);

  root.render(
    <SystailUiProvider
      defaultLightTheme={defaultSchemeLight}
      defaultDarkTheme={defaultSchemeDark}
      defaultMode="light"
    >
      <BackgroundSvgColorGenerator>
        <UserProvider>
          <QueryProvider />
        </UserProvider>
      </BackgroundSvgColorGenerator>
    </SystailUiProvider>
  );

  await i18nextPromise;
};

init();
